@import 'webresources-sources/scss/imports';

.form,
.form-component {
  .form-group {
    .form-group-label {
      color: $color-darkblue;
    }

    .form-group-field {
      .text-muted {
        color: $color-gray-600 !important;
      }
    }

    &.has-success {
      .form-control {
        border-color: $success;
      }
    }

    &.is-disabled {
      .form-group-label {
        color: $color-gray-600;
      }

      input.form-control,
      select.form-control,
      textarea.form-control {
        border-color: $color-gray-500;
      }
    }

    &.form-group-checkbox {
      .form-check {
        input {
          border: 1px solid $color-gray-600;

          &:checked {
            background-color: $color-darkgreen-600;
            border-color: $color-darkgreen-600;
          }
        }

        label {
          color: $color-darkblue;
        }

        input:checked ~ label {
          color: $color-darkblue;
        }
      }
    }
  }

  .form-control {
    background: $color-gray-300;
    color: $color-darkblue;

    &::placeholder {
      color: $color-darkblue;
    }
  }

  input.form-control,
  select.form-control,
  textarea.form-control {
    border: 1px solid $color-gray-600;
  }
}
