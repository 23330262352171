/* bootstrap overwrite */
.form .form-group .form-group-label,
.form-component .form-group .form-group-label {
  color: #001f47;
}
.form .form-group .form-group-field .text-muted,
.form-component .form-group .form-group-field .text-muted {
  color: #657785 !important;
}
.form .form-group.has-success .form-control,
.form-component .form-group.has-success .form-control {
  border-color: #007a62;
}
.form .form-group.is-disabled .form-group-label,
.form-component .form-group.is-disabled .form-group-label {
  color: #657785;
}
.form .form-group.is-disabled input.form-control,
.form .form-group.is-disabled select.form-control,
.form .form-group.is-disabled textarea.form-control,
.form-component .form-group.is-disabled input.form-control,
.form-component .form-group.is-disabled select.form-control,
.form-component .form-group.is-disabled textarea.form-control {
  border-color: #c9d1e3;
}
.form .form-group.form-group-checkbox .form-check input,
.form-component .form-group.form-group-checkbox .form-check input {
  border: 1px solid #657785;
}
.form .form-group.form-group-checkbox .form-check input:checked,
.form-component .form-group.form-group-checkbox .form-check input:checked {
  background-color: #007a62;
  border-color: #007a62;
}
.form .form-group.form-group-checkbox .form-check label,
.form-component .form-group.form-group-checkbox .form-check label {
  color: #001f47;
}
.form .form-group.form-group-checkbox .form-check input:checked ~ label,
.form-component .form-group.form-group-checkbox .form-check input:checked ~ label {
  color: #001f47;
}
.form .form-control,
.form-component .form-control {
  background: #f8f9f9;
  color: #001f47;
}
.form .form-control::placeholder,
.form-component .form-control::placeholder {
  color: #001f47;
}
.form input.form-control,
.form select.form-control,
.form textarea.form-control,
.form-component input.form-control,
.form-component select.form-control,
.form-component textarea.form-control {
  border: 1px solid #657785;
}